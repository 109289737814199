import { template as template_51f8652d19ec4ba1890d84adaaf3325b } from "@ember/template-compiler";
const FKLabel = template_51f8652d19ec4ba1890d84adaaf3325b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
