import { template as template_eff78575aaaa404db469f65045b75248 } from "@ember/template-compiler";
const FKControlMenuContainer = template_eff78575aaaa404db469f65045b75248(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
