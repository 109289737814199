import { template as template_ff3a515c0d5a4dbfa5ee43144993530d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ff3a515c0d5a4dbfa5ee43144993530d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
